import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "20px",
    backgroundColor: theme.palette.primary.light,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(3, 0),
  },
  font: {
    fontFamily: "Lato",
    fontSize: "large",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    textTransform: "none",
  }
}));

const Footer = (props) => {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" className={classes.font}>
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p" className={classes.font}>
          {description}
        </Typography>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};

export default Footer;
