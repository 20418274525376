import * as React from 'react';
import './layout.css';
import '@fontsource/lato'; // Defaults to weight 400.

import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import Footer from './footer';

const pageStyles = {
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const Layout = ({ children }) => (
  <>
    <CssBaseline />

    <main style={pageStyles}>
      {children}
    </main>

    <Footer title="Prealt - Polyglot in real time" description={`© 2020-${new Date().getFullYear()} All Rights Reserved`} />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
